import styled from 'styled-components'

export const Breakpoints = {
  downFromSmallMobile: '(max-width: 479.98px)',
  downFromMiniMobile: '(max-width:375px)',
  upFromMobile: '(min-width: 480px)',
  upFromTablet: '(min-width: 768px)',
  onlyMobile: '(max-width: 767.98px)',
  onlyTablet: '(min-width: 768px) and (max-width: 991.98px)',
  downFromComputer: '(max-width: 991.98px)',
  onlyComputer: '(min-width: 992px)',
  onlyMiniMobile: '(max-width:325px)',
  onlySmallDesktop: '(min-width: 992px) and (max-width: 1199.98px)',
  downFromSmallDesktop: '(max-width: 1199.98px)',
  downFromBigTablet: '(max-width: 1024px)',
  upFromLargeScreen: '(min-width: 1400px)',
  upFromMediumLargeScreen: '(min-width: 1200px) and (max-width: 1650.98px)',
} as const

export const Colors = {
  black: '#000000',
  brandBeige: '#F3F2EF',
  brandBlue: '#92ACD9',
  brandCotton: '#FBFCF9',
  brandGray: '#C0C0C1',
  brandGreen: '#C1CABC',
  brandKhaki: '#E8E3D4',
  brandNavyBlue: '#47566D',
  brandOrange: '#E68A57',
  brandPink: '#C69387',
  brandSmoke: '#F0EEE8',
  brandYellow: '#E2AD50',
  brightBlue: '#A0BEEF',
  brightGreen: '#BEDF76',
  brightOrange: '#FFBB90',
  brightPink: '#FDCAD5',
  brightYellow: '#FFC871',
  grayType: '#E7E6E6',
  green: '#85B451',
  hoverYellow: '#FFBD55',
  lightBlue: '#BCC3CD',
  lightGray: '#E5E5E5',
  lightGreen: '#D9DD88',
  lightKhaki: '#FCFBF7',
  lightNavyBlue: '#B0B9B4',
  lightOrange: '#F4B185',
  lightPink: '#E6CFD4',
  lightYellow: '#EED1A2',
  paleGray: '#FBFBF8',
  white: '#FFFFFF',
}

export const LineBorder = `1.5px solid ${Colors.black}`

export const FontSizes = {
  h1: '50px',
  h1OnlyMobile: '37px',

  h2: '40px',
  h2OnlyMobile: '28px',

  h3: '28px',
  h3OnlyMobile: '22px',

  p: '16px',
  pMedium: '20px',
  pMediumText: '20px',
  pSmall: '14px',
  pXSmall: '12px',

  pOnlyMobile: '16px',
  pSmallOnlyMobile: '16px',

  hamburgerItemTablet: '22px',
  hamburgerItemMobile: '18px',
}

export const GlobalStyles = styled.section<{ forceOpaqueBack?: boolean }>`
  background: ${(props) =>
    props.forceOpaqueBack ? Colors.brandCotton : Colors.white};

  .downFromComputer {
    @media ${Breakpoints.onlyComputer} {
      display: none !important;
    }
  }

  .onlyComputer {
    @media ${Breakpoints.downFromComputer} {
      display: none !important;
    }
  }

  .noMargin {
    margin-bottom: 0px !important;
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  .flex {
    display: flex;
  }
`
