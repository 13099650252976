'use strict'
import React, { useCallback, useEffect } from 'react'

import styled from 'styled-components'

import { Breakpoints } from 'styles/home/system'
import { Company } from 'types/company'
import { ICalendly, MeetingType } from 'types/meeting'
import { User } from 'types/user'

import ScriptLoader from './calendly-loader'

const isCalendlyEvent = (e: MessageEvent) => {
  return e.data.event?.indexOf('calendly') === 0
}

enum CalendlyTypes {
  EVENT_SCHEDULED = 'calendly.event_scheduled',
  EVENT_VIEWED = 'calendly.event_type_viewed',
  DATE_TIME_SELECTED = 'calendly.date_and_time_selected',
}

export interface InlineCalendarProps {
  blockHeaderActions?: boolean
  calendlyUrl: string
  company?: Partial<Company>
  meetingId?: string | null
  onDateTimeSelected?: (e?: MessageEvent) => void
  onEventScheduled?: (e?: MessageEvent) => void
  onEventTypeViewed?: (e?: MessageEvent) => void
  prospectEmail?: string | null
  type?: MeetingType
  user?: Partial<User> | null
  utmCampaign?: string
  utmSource?: string
  fullHeight?: boolean
}

const Wrapper = ({
  blockHeaderActions = true,
  company,
  calendlyUrl,
  meetingId = null,
  onDateTimeSelected = () => {},
  onEventScheduled = () => {},
  onEventTypeViewed = () => {},
  prospectEmail = null,
  type,
  user,
  utmCampaign = '',
  utmSource = '',
  fullHeight = false,
}: InlineCalendarProps) => {
  const Calendly: ICalendly = (window as unknown as { Calendly: ICalendly })
    .Calendly

  // encoding: data <MEETING_TYPE|meetingId|prospectEmail>
  const utmContent = `data <${type}|${meetingId ? meetingId : ''}|${
    prospectEmail ? prospectEmail : ''
  }|${user?.type || ''}> `
  const utmMedium = company ? JSON.stringify(company) : ''

  const handleEventScheduled = useCallback((e: MessageEvent) => {
    if (blockHeaderActions) {
      const headerForm = document.getElementById('headerForm')
      if (headerForm) {
        headerForm.style.pointerEvents = 'none'
      }
      document.body.style.cursor = 'progress'
    }
    onEventScheduled(e)
  }, [])

  const messageEventListener = useCallback((e: MessageEvent) => {
    if (isCalendlyEvent(e)) {
      const calendlyEvent = e.data.event
      switch (calendlyEvent) {
        case CalendlyTypes.EVENT_SCHEDULED:
          handleEventScheduled(e)
          break
        case CalendlyTypes.EVENT_VIEWED:
          onEventTypeViewed(e)
          break
        case CalendlyTypes.DATE_TIME_SELECTED:
          onDateTimeSelected(e)
          break
        default:
          break
      }
    }
  }, [])

  useEffect(() => {
    Calendly.initInlineWidget({
      url: `${calendlyUrl}?hide_gdpr_banner=1`,
      parentElement: document.getElementById('scheduleForm'),
      prefill: {
        ...user,
        email: user?.email ?? prospectEmail ?? '',
      },
      utm: {
        utmContent: utmContent,
        utmMedium: utmMedium,
        utmSource: utmSource,
        utmCampaign: utmCampaign,
        // Hack! utmTerm is mostly unused, so we throw our environment in there
        utmTerm: process.env.GATSBY_ACTIVE_ENV,
      },
    })

    window.addEventListener('message', messageEventListener)

    return () => {
      window.removeEventListener('message', messageEventListener)
      const headerForm = document.getElementById('headerForm')
      if (headerForm) {
        headerForm.style.pointerEvents = 'auto'
      }
      document.body.style.cursor = 'auto'
    }
  }, [])

  return (
    <ScheduleFormContainer
      id="scheduleForm"
      className="calendly-inline-widget"
      $fullHeight={fullHeight}
    />
  )
}

const InlineCalendar = (props: InlineCalendarProps) => (
  <ScriptLoader>
    <Wrapper {...props} />
  </ScriptLoader>
)

export default InlineCalendar

const ScheduleFormContainer = styled.div<{ $fullHeight: boolean }>`
  &&& {
    margin-top: -45px;
    height: ${(props) => (props.$fullHeight ? '100%' : '680px')};
    width: 100%;

    @media ${Breakpoints.downFromSmallDesktop} {
      margin-top: 0;
      height: ${(props) => (props.$fullHeight ? '100%' : '1300px')};
    }

    @media ${Breakpoints.onlyMobile} {
      max-height: ${(props) => (props.$fullHeight ? '100%' : '60vh')};
    }
  }
`
