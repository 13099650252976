import { Project } from './project'
import { Prospect } from './prospect'
import { User } from './user'

export enum MeetingTypeProspect {
  FOLLOW_UP = 'FOLLOW_UP',
  FREE = 'FREE',
  FREE_VISIT_NY = 'FREE_VISIT_NY',
  FREE_VISIT_SF = 'FREE_VISIT_SF',
  TRADE = 'TRADE',
  WEBINAR = 'WEBINAR',
  SELECTION_CENTER = 'SELECTION_CENTER',
}

export enum MeetingTypeProject {
  BUILDZOOM = 'BUILDZOOM',
  BUILDZOOM_INSTALLER = 'BUILDZOOM_INSTALLER',
  CHECK_IN = 'CHECK_IN',
  DESIGN = 'DESIGN',
  DESIGN_FOLLOW_UP = 'DESIGN_FOLLOW_UP',
  HANDOVER = 'HANDOVER',
  PREINSTALL = 'PREINSTALL',
  PRESENTATION = 'PRESENTATION',
  PROJECT_CONSULTATION = 'PROJECT_CONSULTATION',
  PROJECT_PROPOSAL = 'PROJECT_PROPOSAL',
  SELECTION_CENTER = 'SELECTION_CENTER',
}

export type MeetingType = MeetingTypeProject | MeetingTypeProspect

export enum MeetingOutcome {
  FIVE_STARS = 'Said yes; start immediately',
  FOUR_STARS = 'Great fit; needs time',
  THREE_STARS = 'Good fit; could proceed',
  TWO_STARS = 'Just information',
  ONE_STAR = 'Not a fit',
  NO_SHOW = 'No show',
}

interface CalendlyResponse {
  event: string
  payload: {
    event: {
      assigned_to: string[]
      cancel_reason: string
      canceled: boolean
      canceled_at: string
      canceler_name: string
      created_at: string
      end_time: string
      end_time_pretty: string
      extended_assigned_to: {
        name: string
        email: string
        primary: boolean
      }[]
      invitee_end_time: string
      invitee_end_time_pretty: string
      invitee_start_time: string
      invitee_start_time_pretty: string
      location: string
      start_time: string
      start_time_pretty: string
      uuid: string
    }
    event_type: {
      duration: number
      kind: string
      name: string
      slug: string
      owner: {
        type: string
        uuid: string
      }
      uuid: string
    }
    invitee: {
      cancel_reason: string
      canceled: boolean
      canceler_name: string
      canceled_at: string
      created_at: string
      email: string
      is_reschedule: boolean
      first_name: string
      last_name: string
      name: string
      text_reminder_number: string
      timezone: string
      uuid: string
    }
    new_event: {
      assigned_to: string[]
      cancel_reason: string
      canceled: boolean
      canceled_at: string
      canceler_name: string
      created_at: string
      end_time: string
      end_time_pretty: string
      extended_assigned_to: {
        name: string
        email: string
        primary: boolean
      }[]
      invitee_end_time: string
      invitee_end_time_pretty: string
      invitee_start_time: string
      invitee_start_time_pretty: string
      location: string
      start_time: string
      start_time_pretty: string
      uuid: string
    }
    new_invitee: {
      cancel_reason: string
      canceled: boolean
      canceler_name: string
      canceled_at: string
      created_at: string
      email: string
      is_reschedule: boolean
      first_name: string
      last_name: string
      name: string
      text_reminder_number: string
      timezone: string
      uuid: string
    }
    old_event: {
      assigned_to: string[]
      cancel_reason: string
      canceled: boolean
      canceled_at: string
      canceler_name: string
      created_at: string
      end_time: string
      end_time_pretty: string
      extended_assigned_to: {
        name: string
        email: string
        primary: boolean
      }[]
      invitee_end_time: string
      invitee_end_time_pretty: string
      invitee_start_time: string
      invitee_start_time_pretty: string
      location: string
      start_time: string
      start_time_pretty: string
      uuid: string
    }
    old_invitee: {
      cancel_reason: string
      canceled: boolean
      canceler_name: string
      canceled_at: string
      created_at: string
      email: string
      is_reschedule: boolean
      first_name: string
      last_name: string
      name: string
      text_reminder_number: string
      timezone: string
      uuid: string
    }
    questions_and_answers: {
      answer: string
      question: string
    }[]
    questions_and_responses: unknown
    tracking: {
      utm_campaign: string
      utm_content: string
      utm_medium: string
      utm_source: string
      utm_term: string
      salesforce_uuid: string
    }
  }
  time: string
}

export type Meeting = {
  id?: string
  calendlyId?: string
  calendlyResponse?: CalendlyResponse
  calendlyUrl: string
  canceledAt?: Date | string | null
  createdAt?: Date | string
  duration?: number
  metadata: MeetingMetadata
  project?: Project
  prospect?: Prospect
  prospectId?: string
  staffMember?: User
  staffMemberId?: string
  startTime?: Date | string | null
}

export type MeetingMetadata = {
  agenda?: string
  calendar?: 'normal' | 'urgent'
  customerNoShow?: boolean
  location?: string
  name?: string
  notes?: string
  outcome?: MeetingOutcome
  recording?: string
  recordingPassword?: string
  type: MeetingType
  zoomLink?: string
  zoomOccurrenceId?: string
  zoomRegistrantId?: string
  selectionCenter?: {
    identifier?: string
    location?: string
    isSCFollowupScheduled?: boolean
    firstName: string
    lastName: string
    email: string
  }
}

export enum CalendlyEvents {
  EVENT_SCHEDULED = 'calendly.event_scheduled',
  EVENT_VIEWED = 'calendly.event_type_viewed',
  DATE_TIME_SELECTED = 'calendly.date_and_time_selected',
}

export interface ICalendly {
  initInlineWidget?: any
}

export interface MeetingsUrlQueries {
  meeting_id?: string
  meeting_type?: MeetingType
  duration?: number
}
