import React, { useState } from 'react'

import Helmet, { HelmetTags } from 'react-helmet'

interface ScriptLoaderProps {
  children: React.ReactNode
}

const ScriptURL = `https://assets.calendly.com/assets/external/widget.js`

const ScriptLoader = ({ children }: ScriptLoaderProps) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)

  const handleChangeClientState = (_: unknown, addedTags: HelmetTags): void => {
    if (addedTags?.scriptTags) {
      const foundScript = addedTags.scriptTags.find(
        ({ src }: { src: string }) => src === ScriptURL,
      )
      if (foundScript) {
        foundScript.addEventListener('load', () => setScriptLoaded(true), {
          once: true,
        })
      }
    }
  }

  return (
    <>
      <Helmet onChangeClientState={handleChangeClientState}>
        <script async defer src={ScriptURL} />
      </Helmet>
      {scriptLoaded && <> {children} </>}
    </>
  )
}

export default ScriptLoader
